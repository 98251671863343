<template>
  <div class="container">
    <div @keydown.enter="resetPassword()">
      <h1>Resetovať heslo</h1>

      <form class="pt-3">
        <div class="row">
          <div class="form-group col-12 d-none">
            <label for="verificationCode">Verifikačný kód</label>
            <input
              v-model="credentials.code"
              type="text"
              class="form-control"
              id="verificationCode"
              placeholder="Zadajte verifikačný kód ktorý bol odoslaný na Váš email"
              autocomplete="none"
            />
          </div>
          <div class="form-group col-12">
            <label for="newPassword">Nové heslo</label>
            <input
              v-model="credentials.password"
              type="password"
              class="form-control"
              id="newPassword"
              placeholder="Zadajte nové heslo"
              autocomplete="new-password"
            />
          </div>
          <div class="form-group col-12">
            <label for="passwordConfirmation">Potvrdenie hesla</label>
            <input
              v-model="credentials.passwordConfirmation"
              type="password"
              class="form-control"
              id="passwordConfirmation"
              placeholder="Potvrďte nové heslo"
              autocomplete="new-password"
            />
          </div>
        </div>
        <b-button
          variant="primary"
          @click.prevent="resetPassword()"
          :disabled="$toast.isLoading()"
          >Zmeniť heslo</b-button
        >
      </form>
    </div>
  </div>
</template>

<script>
import * as Sentry from "@sentry/vue";
export default {
  data() {
    return {
      credentials: {
        code: "",
        password: "",
        passwordConfirmation: "",
      },
    };
  },

  created() {
    if (this.$store.getters["auth/isLoggedIn"]) this.$router.push("/profil");
    this.credentials.code = new URLSearchParams(
      window.location.search.substring(1)
    ).get("code");
  },

  methods: {
    async resetPassword() {
      this.$toast.clear_loading();

      if (
        !this.credentials.password ||
        !this.credentials.passwordConfirmation
      ) {
        this.$toast.error(this.$t("The password field is required."));
        return;
      }

      if (this.credentials.password !== this.credentials.passwordConfirmation) {
        this.$toast.error(this.$t("The password confirmation does not match."));
        return;
      }

      try {
        await this.$store.dispatch("auth/resetPassword", this.credentials);
        this.$toast.stopLoading();
        this.$router.push("/prihlasit-sa");
      } catch (error) {
        Sentry.captureException(error);
        this.$toast.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  margin-bottom: 5px !important;
}

h5 {
  margin-bottom: 30px;
}
</style>
